import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import get from 'lodash-es/get';

import BlockStyles from '../../BlockStyles';
import LatestArticles from '.';

const propTypes = {
  data: PropTypes.object,
  template: PropTypes.string,
  mode: PropTypes.string
};

const defaultProps = { data: {}, template: '', mode: '' };

function LatestArticlesBlock({ data, template, mode }) {
  const { title, category } = data.primary;
  const articlesQueryResult = useStaticQuery(graphql`
    query ArticlesQuery {
      allPrismicNewsItem(sort: { fields: data___post_date, order: DESC }) {
        edges {
          node {
            type
            uid
            ...NewsInfo
            data {
              post_categories {
                category {
                  uid
                }
              }
            }
          }
        }
      }
    }
  `);
  const tags = (data.items || []).map((item) => item.tag);

  const edges = get(articlesQueryResult, 'allPrismicNewsItem.edges', []);

  const filterByTag = (edge) => {
    const newsItemTags = get(edge, 'node.tags', []);
    const foundIndex = newsItemTags.findIndex((newsItemTag) =>
      tags.includes(newsItemTag)
    );
    return foundIndex > -1;
  };

  const filterByCategory = (edge) => {
    const postCategories = get(edge, 'node.data.post_categories', []);
    const foundIndex = postCategories.findIndex(
      (cat) => cat.category.uid === category.uid
    );
    return foundIndex > -1;
  };

  const filterFunction = mode === 'tag' ? filterByTag : filterByCategory;
  const articles = edges.filter(filterFunction);

  return (
    <BlockStyles template={template}>
      <LatestArticles title={title.text} articles={articles.slice(0, 3)} />
    </BlockStyles>
  );
}

LatestArticlesBlock.propTypes = propTypes;
LatestArticlesBlock.defaultProps = defaultProps;

export default LatestArticlesBlock;
